/* set up material design theme */
@use '@angular/material' as mat;
@import 'palette';
@import 'viewport';
$custom-typography: mat.define-legacy-typography-config(
  $font-family: 'Open Sans, sans-serif',
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();

$app-primary: mat.define-palette($app-primary-palette);

$app-accent: mat.define-palette($app-accent-palette);

$app-theme: mat.define-light-theme($app-primary, $app-accent);
@include mat.legacy-core-theme($app-theme);

@include mat.all-legacy-component-themes($app-theme);

@include mat.legacy-button-theme(
    mat.define-light-theme(
        mat.define-palette($app-primary-palette, 900),
        mat.define-palette($app-accent-palette, 200)
    )
);

//Variables
$faxe-base-colors: (
  identification-color: #1976D2, //map_get($app-accent-palette, 600),
  error-red: #F44336,
  background-red: #FFE7EC,
  on-green: #5C7054,
  background-green: #DAF1D6,
  on-yellow: #877237,
  background-yellow: #FFEFBC,
  primary-blue: #1976D2,
  background-blue: #E3F2FD,
  hover-blue: #1565C0,
  visited-blue: #006AB6,
  plain-white: #FFF,
  pitch-black: #000,
  background-grey: #F4F5F7,
);

$interaction-colors-variables: (
  app-identification-color: map_get($faxe-base-colors, identification-color),
  app-interaction-primary: map_get($faxe-base-colors, primary-blue),
);

$greyscales-variables: (
  grey-50: rgba(0, 0, 0, 0.02),
  grey-100: rgba(0, 0, 0, 0.04),
  grey-200: rgba(0, 0, 0, 0.065),
  grey-300: rgba(0, 0, 0, 0.12),
  grey-400: rgba(0, 0, 0, 0.26),
  grey-500: rgba(0, 0, 0, 0.38),
  grey-600: rgba(0, 0, 0, 0.54),
  grey-700: rgba(0, 0, 0, 0.62),
  grey-800: rgba(0, 0, 0, 0.74),
  grey-900: rgba(0, 0, 0, 0.87),
);

$status-colors-variables: (
  status-error: map_get($faxe-base-colors, error-red),
  status-red: map_get($faxe-base-colors, background-red),
  status-yellow: map_get($faxe-base-colors, background-yellow),
  status-green: map_get($faxe-base-colors, background-green),
  status-info: map_get($faxe-base-colors, background-blue),
);

$text-colors-variables: (
  txt-white: map_get($faxe-base-colors, plain-white),
  txt-red: map_get($faxe-base-colors, error-red),
  txt-green: map_get($faxe-base-colors, on-green),
  txt-yellow: map_get($faxe-base-colors, on-yellow),
  txt-blue: map_get($faxe-base-colors, primary-blue),
);

$body-variables: (
  background-color-grey: map_get($faxe-base-colors, background-grey),
  background-color-white: map_get($faxe-base-colors, plain-white)
);

// Generate CSS variables available in root
:root {
  @each $name, $var in $viewport-breakpoints {
    --viewport-#{$name}: #{$var};
  }

  @each $name, $var in $app-accent-palette {
    @if type-of($var) != 'map' {
      --palette-#{$name}: #{$var};
    }
  }

  @each $name, $var in $interaction-colors-variables {
    --#{$name}: #{$var};
  }

  @each $name, $var in $greyscales-variables {
    --#{$name}: #{$var};
  }

  @each $name, $var in $status-colors-variables {
    --#{$name}: #{$var};
  }

  @each $name, $var in $text-colors-variables {
    --#{$name}: #{$var};
  }

  @each $name, $var in $body-variables {
    --#{$name}: #{$var};
  }

  .mat-radio-group-horizontal,
  .mat-checkbox-horizontal{
    display: flex;
    flex-direction: row;

    & mat-checkbox {
      & .mat-checkbox-layout .mat-checkbox-label {
        line-height: 20px !important;
      }
      margin-top: 4px;
      margin-bottom: 4px;
    }

    & mat-radio-button {
      margin: 5px;
    }
  }

}
