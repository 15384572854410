@import 'viewport';

.notification-area {
  display: none;
  opacity: 0;
  max-height: 0;
  transition: opacity 500ms ease-in, max-height 500ms ease-in;

  &.ok {
    background-color: var(--status-green);
    color: var(--txt-green);
  }

  &.warn {
    background-color: var(--status-yellow);
    color: var(--txt-yellow);
  }

  &.error {
    background-color: var(--status-red);
    color: var(--txt-red);
  }

  &.info {
    background-color: var(--status-info);
    color: var(--txt-blue);
  }

  &_message {
    flex: 1;
    padding: 2px 6px;
  }

  &_heading {
    font-weight: bold;
  }

  &_description {
    white-space: pre-line;
  }

  &_close-button {
    cursor: pointer;
  }

  &.info, &.error, &.warning, &.ok {
    max-height: 100%;
    min-height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    opacity: 1;
    padding-top: 11px;
    padding-bottom: 11px;

    @media screen and (min-width: map-get($viewport-breakpoints, xsmall)) {
      padding-left: 16px;
      padding-right: 16px;
    }

    @media screen and (min-width: map-get($viewport-breakpoints, large)) {
      padding-left: 48px;
      padding-right: 48px;
    }
  }

  & ul {
    padding-left: 20px;
  }

  &.dialog {
    padding-left: 24px;
    padding-right: 24px;
    margin-left: -24px;
    margin-right: -24px;
  }
}
