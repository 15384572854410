/* import variables, mixins and modules*/
@import 'partials/material-theme';
@import 'partials/base';
@import 'partials/icons';
@import 'partials/rubriker';
@import 'partials/mat-tabs';
@import 'partials/mat-table';
@import 'partials/page-wrapper';
@import 'partials/header';
@import 'partials/notification-area';
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
  body {
  font-family: var(--font-family);
}
