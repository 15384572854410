.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background: var(--app-identification-color);
}

.mat-tab-label.mat-tab-label-active, .mat-tab-link.mat-tab-label-active {
  color: var(--app-identification-color);
  font-weight: 600;
  opacity: 1;
}

.mat-tab-label, .mat-tab-link {
  cursor: pointer;
  opacity: 1 !important;
}

.mat-tab-body {
  padding: 16px;
}

