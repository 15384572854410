em.icon {
  display: none;

  &.icon-user-avatar {
    display: inline-block;

    &::after {
      content: url(../../icons/user-avatar.svg);
    }
  }
}
