@import 'viewport';
header {
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 0;

  & > * > div {
    @media screen and (min-width: map_get($viewport-breakpoints, xsmall)) {
      padding-left: 16px;
      padding-right: 16px;
    }

    @media screen and (min-width: map_get($viewport-breakpoints, large)) {
      padding-left: 48px;
      padding-right: 48px;
    }
  }
}
