a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

html,
body {
  height: 100%;
  background-color: var(--background-color-grey);
}

hr {
  border: 0;
  border-top: 1px solid var(--grey-500);
}

p {
  line-height: 20px;
}

mat-form-field {
  line-height: 20px !important;
}

.mat-radio-label-content {
  padding-left: 16px !important;
}

.mat-form-field-appearance-outline.mat-form-field-disabled
.mat-form-field-outline {
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 6px;
}

faxe-hogersida .mat-expansion-panel-header {
  border-top: 1px solid var(--grey-300);
}

.hidden {
  display: none !important;
}

.error-frame {
  border: 2px solid var(--status-error) !important;
  border-radius: 5px;
  margin-top: 2px;

  &.chip {
    width: fit-content;
  }
}

.error-chip {
  border: 2px solid var(--status-error) !important;
}

.mat-drawer-content {
  background-color: var(--background-color-grey);
}

.mat-chip-list-stacked {
  // TODO: går det att förbättra selector?
  mat-chip[color='custom'][selected='true'],
  .mat-chip.mat-custom.mat-chip-selected {
    width: unset;

    &:focus-within {
      &::after {
        opacity: 0.16;
      }
    }
  }
}

//Custom color parameter for Faxe-chiplist color
mat-chip[color='custom'][selected='true'],
.mat-chip.mat-custom.mat-chip-selected {
  background-color: var(--palette-50);
  color: var(--palette-800);
  flex-direction: row;
  justify-content: space-between;
}

//Custom och pacolor stallningstagande foljd
.mat-chip-list-pafoljd,
.mat-chip-list-stallningstagande {
  mat-chip[color='custom'].mat-chip-selected,
  .mat-chip.mat-custom.mat-chip-selected {
    background-color: var(--palette-50);
    color: var(--palette-800);
    font-weight: 600;
  }

  mat-chip[color='custom'].mat-chip-selected:focus,
  mat-chip[color='custom'].mat-chip-selected:hover,
  mat-chip[color='custom'].mat-chip-selected:after {
    background-color: var(--palette-100);
  }

  mat-chip[color='custom'].mat-chip-standard:focus,
  mat-chip[color='custom'].mat-chip-standard:hover {
    background-color: var(--palette-400);
  }

  mat-chip[color='custom'].mat-chip-standard:after {
    background-color: transparent;
  }

  mat-chip[color='custom']:hover {
    cursor: pointer;
  }
}

  //Custom och pacolor stallningstagande foljd
    .mat-chip-list-faults {
      mat-chip[color='custom'].mat-chip-selected,
      .mat-chip.mat-custom.mat-chip-selected {
        background-color: var(--status-green);
        color: var(--txt-green);
        font-weight: 600;
      }

      mat-chip[color='custom'].mat-chip-selected:focus,
      mat-chip[color='custom'].mat-chip-selected:hover,
      mat-chip[color='custom'].mat-chip-selected:after {
        background-color: var(--status-green);
      }

      mat-chip[color='custom'].mat-chip-standard:focus,
      mat-chip[color='custom'].mat-chip-standard:hover {
        background-color: var(--status-green);
      }

      mat-chip[color='custom'].mat-chip-standard:after {
        background-color: transparent;
      }

      mat-chip[color='custom']:hover {
        cursor: pointer;
      }
    }



.mat-dialog-container {
  overflow: hidden;
}

.mat-radio-group-vertical,
.mat-checkbox-vertical {
  display: flex;
  flex-direction: column;

  & mat-checkbox {
    & .mat-checkbox-layout .mat-checkbox-label {
      line-height: 20px !important;
    }

    margin-top: 4px;
    margin-bottom: 4px;
  }

  & mat-radio-button {
    margin: 5px;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.bold {
  font-weight: bold;
}

.red {
  fill: var(--txt-red);
  color: var(--txt-red);
}

.redbold {
  fill: var(--txt-red);
  color: var(--txt-red);
  font-weight: bold;
}
