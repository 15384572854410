@import 'viewport';

.content-page-wrapper {
  margin: 60px auto 0 auto;

  @media screen and (min-width: map-get($viewport-breakpoints, xsmall)) {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: map-get($viewport-breakpoints, medium)) {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: relative;

    &.one-column {
      & > * {
        flex: 1;
        padding: 0 16px;
      }
    }

    &.two-columns {
      & > * {
        flex: 0 0 calc(50% - 24px);
      }
    }
    &.three-columns {
      & > * {
        flex: 0 0 calc(33% - 16px);
      }
    }

  }

  @media screen and (min-width: map-get($viewport-breakpoints, large)) {
    padding: 0 48px;

    &.one-column {
      padding: 0 32px;
    }
  }
}

.content-page-wrapper-no-toolbar {
  margin: 20px auto 0 auto;

  @media screen and (min-width: map-get($viewport-breakpoints, xsmall)) {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: map-get($viewport-breakpoints, medium)) {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: relative;

    &.one-column {
      & > * {
        flex: 1;
        padding: 0 16px;
      }
    }

    &.two-columns {
      & > * {
        flex: 0 0 calc(50% - 24px);
      }
    }
    &.three-columns {
      & > * {
        flex: 0 0 calc(33% - 16px);
      }
    }

  }

  @media screen and (min-width: map-get($viewport-breakpoints, large)) {
    padding: 0 48px;

    &.one-column {
      padding: 0 32px;
    }
  }
}

.notification-wrapper {
  margin-top: -4px;
  min-height: 1px;
  position: fixed;
  z-index: 9;
  width: 100%;
}

.progress-bar {
  margin-top: 50px;
  min-height: 4px;
  width: 100%;
}
