h1 {
  font-size: 20px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0.0025em;
  color: var(--grey-800);
}

h2 {
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 0.005em;
  line-height: 24px;
  color: var(--grey-900);
}

h3 {
  padding-top: 10px;
  padding-bottom: 12px;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.0015em;
  line-height: 20px;
  color: var(--grey-900);
}

h4 {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.0035em;
  line-height: 20px;
  color: var(--grey-900);
}

h5 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: var(--grey-900);
}
