table {
  width: 100%;
}

tr.header.row:focus,
tr.header-row:focus-within,
tr.element-row:focus,
tr.element-row:focus-within {
  background-color: var(--background-color-grey);
}

tr.element-row:hover {
  background-color: var(--background-color-grey);
  cursor: pointer;
}

.mat-header-cell {
  color: var(--grey-900);
  font-weight: 600;
  font-size: 14px;
}

.paginator .mat-form-field-appearance-outline .mat-form-field-outline-end,
.paginator .mat-form-field-appearance-outline .mat-form-field-outline-start {
  border: none;
}

.paginator .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: none;
}

.paginator, .mat-paginator-page-size .mat-select-trigger {
  font-size: 14px;
}
